html,
body,
#root {
    display: block;
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
}

a {
    text-decoration: none;
}
